import Vue from 'vue';
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import { TOAST } from '../../../../constants';
import STORE from '@/store/types';

export default Vue.extend({
  name: 'UploadMediaModal',

  props: {
    modalStatus: {
      type: Boolean,
      required: true
    },
    typeMedia: {
      type: String,
      required: true
    }
  },

  data: () => ({
    countesLoadFile: 0,
    inputFiles: [
      { loadingImage: false, body: null, id: 'input0', idImage: 'image0', childStatus: true, classContent: 'upload-media__content-files-parent', classLabel: 'upload-media__content-files-parent-label' },
      { loadingImage: false, body: null, id: 'input1', idImage: 'image1', childStatus: true, classContent: 'upload-media__content-files-parent upload-media__content-files-parent-short', classLabel: 'upload-media__content-files-parent-label upload-media__content-files-parent-label-short' },
      { loadingImage: false, body: null, id: 'input2', idImage: 'image2', childStatus: true, classContent: 'upload-media__content-files-parent upload-media__content-files-parent-short', classLabel: 'upload-media__content-files-parent-label upload-media__content-files-parent-label-short' },
      { loadingImage: false, body: null, id: 'input3', idImage: 'image3', childStatus: true, classContent: 'upload-media__content-files-parent upload-media__content-files-parent-short', classLabel: 'upload-media__content-files-parent-label upload-media__content-files-parent-label-short' }
    ],
    albumName: null,
    headquater: {},
    dataFiles: [],
    buttonStatus: true,
    dataEmitToast: false,
    albumPictures: [],
    mediaLoading: false,
    stateToast: {}
  }),

  created() {
    this.headquater = this.getHeadquaterSelectedFromUserProfile();
  },

  methods: {
    modalClose() {
      this.$emit('modalEmmit', false);
      this.inputFiles.forEach((input, i) => {
        if (this.inputFiles[i].childStatus === false) {
          this.inputFiles[i].childStatus = true;
        }
        this.inputFiles[i].body = null;
      });
      this.dataFiles = [];
      this.buttonStatus = true;
    },

    setName(e) {
      this.albumName = e.target.value;
      this.validateButtonStatus();
    },

    clearImage(id, i) {
      const image = document.getElementById(id);
      image.src = '';
      const element = this.inputFiles[i];
      element.childStatus = !this.inputFiles[i].childStatus;
      element.body = null;

      this.dataFiles.splice(this.dataFiles.indexOf(id), 1);

      this.validateButtonStatus();
    },

    validateButtonStatus() {
      if (this.dataFiles.length > 0 && this.albumName && this.albumName !== '') {
        this.buttonStatus = false;
      } else {
        this.buttonStatus = true;
      }
    },

    async loadFile(e, id, i) {
      if (e.target.files[0]) {
        this.countesLoadFile = i;
        this.inputFiles[i].loadingImage = true;
        this.buttonStatus = true;
        this.mediaLoading = true;

        const file = e.target.files[0];
        let statusPanoramic = null;

        const reader = new FileReader();

        reader.readAsDataURL(file);
        /* eslint-disable */
        reader.onload = function (event) {
          const image = new Image();
          image.src = event.target.result;
          /* eslint-disable */
          image.onload = function () {
            if (this.width/2 >= this.height) {
              statusPanoramic = true;
            } else {
              statusPanoramic = false;
            }
          };
        };

        const image = document.getElementById(id);
        image.src = URL.createObjectURL(file);

        const storageRef = window.firebase.storage().ref(`images/Albums/${file.name}`);
        await storageRef.put(file)
        .then(() => {
          const BASE = 'https://firebasestorage.googleapis.com/v0/b/geochef-dev.appspot.com/o/images%2FAlbums';
          const urlStorageImage = `${BASE}%2F${file.name}?alt=media`;

          const dataBody = {
            name: file.name,
            description: '',
            url: urlStorageImage,
            type: this.typeMedia,
            panoramic: statusPanoramic,
            belongsTo: '',
            headquater: this.headquater.id
          };

          this.inputFiles[i].body = dataBody;
          this.dataFiles.push(id);
          this.inputFiles[i].loadingImage = false;
          this.inputFiles[i].childStatus = !this.inputFiles[i].childStatus;

          if (this.inputFiles[this.countesLoadFile].body) {
            this.buttonStatus = false;
            this.mediaLoading = false;
            this.validateButtonStatus();
          }
        })
        .catch(error => {
          console.error('ERROR: ', error);
          this.stateToast = { show: true, status: TOAST.ERROR, message: 'Error al intentar subir la imágen' }
          this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, this.stateToast)
        });
      }
    },

    async uploadMediaFile() {
      if (this.albumName && this.albumName !== '') {
        const dataBodyCreateAlbum = { name: this.albumName, description: '', belongsTo: this.headquater.id };
        await this.createAlbum(dataBodyCreateAlbum);
      }
    },

    async createAlbum(dataBody) {
      await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, true)

      const { ok, result } = await surePromise(requests.createAlbum(dataBody));
      if (ok) {
        await this.uploadMedia(result.id);
      } else {
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, false)
        this.stateToast = { show: true, status: TOAST.ERROR, message: 'Error al intentar crear el album' };
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, this.stateToast);
      }
    },

    async uploadMedia(storeId) {
      await this.inputFiles.forEach(async (_, i) => {
        if (this.inputFiles[i].body) {
          this.inputFiles[i].body.belongsTo = storeId;
          const { ok } = await surePromise(requests.uploadMedia(this.inputFiles[i].body));
          if (ok) {
            this.dataEmitToast = true;
          } else {
            this.dataEmitToast = false;
          }
        }
      });

      await this.getDataAlbums();
      await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, false)
      this.stateToast = {
        show: this.dataEmitToast,
        status: this.dataEmitToast ? TOAST.SUCCESS : TOAST.ERROR,
        message: this.dataEmitToast ? 'Imágenes cargadas exitosamente' : 'Error al cargar las imágenes'
      };
      await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, this.stateToast);
      this.modalClose();
    },

    async getDataAlbums() {
      await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, true)
      this.albumPictures = [];
      const { ok, result } = await surePromise(requests.getAllAlbums({ belongsTo: this.headquater.id, limit: 100 }));

      if (ok && result.length > 0) {
        result.forEach(async (gallery) => {
          gallery.picture = await this.getPictures(gallery.id);
          this.albumPictures.push(gallery);
        });
        await this.$store.dispatch(STORE.ACTIONS.ALBUMS.DATA_ALBUM, this.albumPictures);
      }
      await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, false)
      this.stateToast = {show: true, status: TOAST.SUCCESS, message: 'Album creado exitosamente'};
      await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, this.stateToast);
    },

    async getPictures(albumId) {
      const { result } = await surePromise(requests.getAlbumPicture({ belongsTo: albumId, limit: 1 }));
      return result[0].url;
    }
  }
});
